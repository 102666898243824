import {HttpClient} from '@angular/common/http'
import {Injectable, inject} from '@angular/core'
import {Observable} from 'rxjs'
import {BankIDVerificationData} from 'sparbanken-syd-bil'
import {environment} from '../../environments/environment'
import {ConfigService} from './config.service'


@Injectable({
  providedIn: 'root'
})
export class DataService {

  private configService = inject(ConfigService)
  private http = inject(HttpClient)


  public getAllTransactions(): Observable<BankIDVerificationData[]> {
    let url = `${environment.bilAPIUrl}/admin` // Default URL

    if (this.configService.isAdmin$()) {
      url = `${environment.bilAPIUrl}/admin` // Updates the URL if isAdmin is true
    } else if (this.configService.isUser$()) {
      url = `${environment.bilAPIUrl}/user` // Updates the URL if isUser is true
    }

    return this.http.get<BankIDVerificationData[]>(url)
  }
}
