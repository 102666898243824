import {registerLocaleData} from '@angular/common'
import localeFr from '@angular/common/locales/fr'
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild
} from '@angular/core'
import {FormControl, ReactiveFormsModule} from '@angular/forms'
import {MatMiniFabButton} from '@angular/material/button'
import {MatFormField, MatLabel} from '@angular/material/form-field'
import {MatIcon} from '@angular/material/icon'
import {MatInput} from '@angular/material/input'
import {MatPaginator, MatPaginatorIntl} from '@angular/material/paginator'
import {MatProgressSpinner} from '@angular/material/progress-spinner'
import {MatOption, MatSelect} from '@angular/material/select'
import {MatSort, MatSortHeader} from '@angular/material/sort'
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatNoDataRow,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource
} from '@angular/material/table'
import {MatTooltip} from '@angular/material/tooltip'
import {SparbankenUser} from '@sparbanken-syd/sparbanken-syd-bankid'
import {first, startWith, switchMap} from 'rxjs'
import {BankIDVerificationData} from 'sparbanken-syd-bil'
import {DataService} from '../services/data.service'
import {UserService} from '../services/user.service'

registerLocaleData(localeFr)

@Component({
  selector: 'spb-id-list',
  templateUrl: './id-list.component.html',
  styleUrls: ['./id-list.component.scss'],

  imports: [
    MatFormField,
    ReactiveFormsModule,
    MatSelect,
    MatOption,
    MatIcon,
    MatProgressSpinner,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatSortHeader,
    MatTooltip,
    MatCellDef,
    MatCell,
    MatPaginator,
    MatNoDataRow,
    MatHeaderRow,
    MatRow,
    MatHeaderRowDef,
    MatRowDef,
    MatLabel,
    MatInput,
    MatMiniFabButton
  ]
})
export class IdListComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort) daSort = new MatSort()
  @ViewChild(MatPaginator) paginator = new MatPaginator(new MatPaginatorIntl(), ChangeDetectorRef.prototype)

  public columnsToDisplay: string[] = ['timeStamp', 'personalNumber', 'name', 'age', 'coworkerName', 'office', 'copy', 'save']
  public filterControl = new FormControl<string>('', {nonNullable: true})
  public dataSource: MatTableDataSource<BankIDVerificationData> = new MatTableDataSource<BankIDVerificationData>()
  public bid: BankIDVerificationData[] = []
  public copyText = ''
  public offices: string[] = []
  public showTable = false

  /**
   * Used when updating list
   */
  public users: SparbankenUser[] = []

  constructor(
    private dataService: DataService,
    private userService: UserService
  ) {
  }

  public ngOnInit(): void {
    this.filterControl.valueChanges.pipe(
      startWith('')
    ).subscribe({
      next: (value: string) =>
        this.dataSource.filter = value.trim().toLowerCase()
    })
    this.loadList()

    /**
     * Get all sparbanken offices
     */
    this.getOffices()
  }

  public copyInfo(bankIDVerificationData: BankIDVerificationData) {
    const jsonData = JSON.stringify(bankIDVerificationData)

    navigator.clipboard.writeText(jsonData)
      .then(() => {
        this.copyText = 'Kopierad till urklipp'

      })
      .catch(() => {
        this.copyText = 'Kopieringen misslyckades'
      })
    setTimeout(() => {
      this.copyText = ''
    }, 1500)
  }

  public saveInfo(bankIDVerificationData: BankIDVerificationData) {
    if (!bankIDVerificationData.user) {
      this.copyText = 'Felaktig verifiering - Kan inte sparas'
      setTimeout(() => {
        this.copyText = ''
      }, 2500)
      return
    }
    // Remove undefined and gdprRemove properties from the object
    for (const prop in bankIDVerificationData) {
      if (bankIDVerificationData[prop as keyof BankIDVerificationData] === undefined || prop === 'gdprRemove') {
        delete bankIDVerificationData[prop as keyof BankIDVerificationData]
      }
    }

    // Convert data to JSON format
    const json_data = JSON.stringify(bankIDVerificationData)

    /// Create the filename based on the scanned identification number and name
    const sub = bankIDVerificationData.user.personalNumber
    const name = bankIDVerificationData.user.name
    const filename = `${sub}_${name}_intyg.txt`

    // Create blog and url to click
    const blob = new Blob([json_data], {type: 'application/json'})
    const url = URL.createObjectURL(blob)
    const downloadLink = document.createElement('a')
    downloadLink.href = url
    downloadLink.download = filename
    downloadLink.click()

    // Clear the URL object after the download
    URL.revokeObjectURL(url)
  }

  public updateList() {
    this.showTable = false
    this.dataService.getAllTransactions().pipe(first()).subscribe((updatedBids: BankIDVerificationData[]) => {
      this.dataSource.data = updatedBids
        .sort((a: BankIDVerificationData, b: BankIDVerificationData) => b.timeStamp - a.timeStamp)
        .map((item: any) => this.transformTransactionData(item))
      this.showTable = true
      setTimeout(() => {
        this.dataSource.sort = this.daSort
        this.dataSource.paginator = this.paginator
      }, 1)
    })
  }

  public loadList() {
    this.showTable = false
    this.dataService.getAllTransactions().pipe(
      switchMap((bid: BankIDVerificationData[]) => {
        this.bid = bid
        return this.userService.getUsers()
      }),
      first()
    ).subscribe((users: SparbankenUser[]) => {
      this.users = users
      this.dataSource.data = this.bid
        .sort((a: BankIDVerificationData, b: BankIDVerificationData) => b.timeStamp - a.timeStamp)
        .map((item: BankIDVerificationData) => this.transformTransactionData(item))
      this.showTable = true
      setTimeout(() => {
        this.dataSource.sort = this.daSort
        this.dataSource.paginator = this.paginator
      }, 1)
    })
  }


  /**
   * Make gdprRemove date more readable
   * @param timestamp
   */
  public convertDate(timestamp: number) {
    const date = new Date(timestamp * 1000)
    const convertedDate = date.toLocaleDateString('sv-SE')
    return timestamp ? `Gallras ${convertedDate}` : ''
  }

  public ngAfterViewInit(): void {
    this.dataSource.sort = this.daSort
  }

  private getOffices(): void {
    this.userService.getOffices()
      .pipe(first())
      .subscribe({
        next: ((offices: string[]) => this.offices = offices)
      })
  }

  private transformTransactionData(item: any): BankIDVerificationData {
    const formattedTimeStamp = new Date(item.timeStamp).toLocaleString('sv-SE', {
      dateStyle: 'short',
      timeStyle: 'short'
    })
    const user: SparbankenUser | undefined = this.users.find((user: SparbankenUser) => user.sub === item.sub)
    return {
      ...item,
      name: item.user?.name,
      age: item.user?.age,
      timeStamp: formattedTimeStamp,
      personalNumber: item.user?.personalNumber,
      coworkerName: user?.name ?? item.sub,
      office: user?.office ?? ''
    }
  }
}
