import {InjectionToken} from '@angular/core'

/**
 * Defines and exports an injection token that replaces the browser
 * window object.
 */
export const WINDOW = new InjectionToken<Window>('window')

/**
 * Random variables used in the app
 */
export const LOGIN_ROUTE_PATH = 'login'
export const ADMIN_ROLE = 'idAdmin'
export const USER_ROLE = 'idUser'
export const ADMIN_ROUTE_PATH = 'admin'