import {provideHttpClient, withInterceptors} from '@angular/common/http'
import {
  ApplicationConfig,
  inject,
  provideAppInitializer,
  provideZoneChangeDetection
} from '@angular/core'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'
import {
  provideAnimationsAsync
} from '@angular/platform-browser/animations/async'
import {
  provideRouter,
  withHashLocation,
  withInMemoryScrolling
} from '@angular/router'
import {routes} from './app.routes'
import {WINDOW} from './application/app'
import {authInterceptor} from './application/auth.interceptor'

import {responseInterceptor} from './application/response.interceptor'
import {ConfigService} from './services/config.service'

export const appConfig: ApplicationConfig = {
  providers: [
    provideAppInitializer(() => {
      const initializerFn = ((s: ConfigService) => () =>
        s.bootstrap())(inject(ConfigService))
      return initializerFn()
    }),
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        floatLabel: 'always',
        hideRequiredMarker: true,
        appearance: 'outline'
      }
    },
    provideZoneChangeDetection({eventCoalescing: true}),
    provideRouter(routes, withHashLocation(), withInMemoryScrolling({anchorScrolling: 'enabled'})),
    provideHttpClient(
      withInterceptors([
        authInterceptor,
        responseInterceptor
      ])
    ),
    {provide: WINDOW, useValue: window},
    provideAnimationsAsync()
  ]
}