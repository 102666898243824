import {provideHttpClient, withInterceptors} from '@angular/common/http'
import {
  ApplicationConfig,
  inject,
  provideAppInitializer,
  provideZoneChangeDetection
} from '@angular/core'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'
import {MatPaginatorIntl} from '@angular/material/paginator'
import {
  provideAnimationsAsync
} from '@angular/platform-browser/animations/async'
import {
  provideRouter,
  withHashLocation,
  withInMemoryScrolling
} from '@angular/router'
import {SpbPaginatorSv} from '@sparbanken-syd/sparbanken-syd-theme'
import {routes} from './app.routes'
import {WINDOW} from './application/app'
import {authInterceptor} from './application/auth.interceptor'
import {responseInterceptor} from './application/response.interceptor'
import {ConfigService} from './services/config.service'

export const appConfig: ApplicationConfig = {
  providers: [
    provideAppInitializer(() => {
      const service = inject(ConfigService)
      return service.bootstrap()
    }),
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        floatLabel: 'always',
        hideRequiredMarker: true,
        appearance: 'outline'
      }
    },
    provideZoneChangeDetection({eventCoalescing: true}),
    provideRouter(routes, withHashLocation(), withInMemoryScrolling({anchorScrolling: 'enabled'})),
    provideHttpClient(
      withInterceptors([
        authInterceptor,
        responseInterceptor
      ])
    ),
    {provide: WINDOW, useValue: window},
    provideAnimationsAsync(),
    {provide: MatPaginatorIntl, useClass: SpbPaginatorSv}
  ]
}