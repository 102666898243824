import {AsyncPipe} from '@angular/common'
import {Component, inject} from '@angular/core'
import {RouterOutlet} from '@angular/router'
import {FooterComponent} from '@sparbanken-syd/sparbanken-syd-theme'
import version from '../assets/package.json'
import {HeaderComponent} from './common/header/header.component'
import {ConfigService} from './services/config.service'

@Component({
  selector: 'spb-root',
  templateUrl: './app.component.html',
  imports: [
    RouterOutlet,
    AsyncPipe,
    HeaderComponent,
    FooterComponent
  ],
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public configService: ConfigService = inject(ConfigService)
  public version = version.version
  public copyrightYears = `2023-${new Date().getFullYear()}`
}