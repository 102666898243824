import { MatPaginatorIntl } from '@angular/material/paginator';
import * as i0 from '@angular/core';
import { Injectable, InjectionToken, Component, EventEmitter, inject, Input, Output, Inject, forwardRef, Directive, HostListener, Pipe } from '@angular/core';
import { ReplaySubject, Subject, timer, BehaviorSubject, Subscription, of, first, noop } from 'rxjs';
import { takeUntil, switchMap, map, filter } from 'rxjs/operators';
import { MatButton } from '@angular/material/button';
import { CdkScrollable } from '@angular/cdk/scrolling';
import * as i1$1 from '@angular/material/dialog';
import { MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as i1 from '@angular/common/http';
import * as i4 from '@angular/material/snack-bar';
import { MatIcon } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { NgTemplateOutlet, formatNumber } from '@angular/common';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import * as i1$2 from '@angular/material/stepper';
import { verify } from '@sparbanken-syd/personnummer';
function FooterComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵlistener("click", function FooterComponent_Conditional_1_Template_div_click_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.openDialog());
    });
    i0.ɵɵtext(1, "Tredjepartsprogramvara");
    i0.ɵɵelementEnd();
  }
}
function LogoComponent_Conditional_1_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function LogoComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, LogoComponent_Conditional_1_ng_container_0_Template, 1, 0, "ng-container", 4);
  }
  if (rf & 2) {
    i0.ɵɵnextContext();
    const image_r1 = i0.ɵɵreference(6);
    i0.ɵɵproperty("ngTemplateOutlet", image_r1);
  }
}
function LogoComponent_Conditional_2_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0, 2);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("routerLink", ctx_r1.routePath);
  }
}
function LogoComponent_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, LogoComponent_Conditional_2_ng_container_0_Template, 1, 1, "ng-container", 5);
  }
  if (rf & 2) {
    i0.ɵɵnextContext();
    const image_r1 = i0.ɵɵreference(6);
    i0.ɵɵproperty("ngTemplateOutlet", image_r1);
  }
}
function LogoComponent_Conditional_3_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function LogoComponent_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 3);
    i0.ɵɵtemplate(1, LogoComponent_Conditional_3_ng_container_1_Template, 1, 0, "ng-container", 4);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵnextContext();
    const image_r1 = i0.ɵɵreference(6);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", image_r1);
  }
}
function LogoComponent_Conditional_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-icon");
    i0.ɵɵtext(1, "chevron_right");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(2, "div", 6);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("routerLink", ctx_r1.routePath);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r1.title);
  }
}
function LogoComponent_ng_template_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "img", 7);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("routerLink", ctx_r1.routePath);
  }
}
class SpbPaginatorSv extends MatPaginatorIntl {
  constructor() {
    super(...arguments);
    this.itemsPerPageLabel = 'Rader per sida';
    this.nextPageLabel = 'Nästa';
    this.previousPageLabel = 'Förra';
    this.firstPageLabel = 'Första';
    this.lastPageLabel = 'Sista';
    this.getRangeLabel = (page, pageSize, length) => {
      if (length === 0 || pageSize === 0) {
        return `0 av ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return `${startIndex + 1} – ${endIndex} av ${length}`;
    };
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵSpbPaginatorSv_BaseFactory;
      return function SpbPaginatorSv_Factory(__ngFactoryType__) {
        return (ɵSpbPaginatorSv_BaseFactory || (ɵSpbPaginatorSv_BaseFactory = i0.ɵɵgetInheritedFactory(SpbPaginatorSv)))(__ngFactoryType__ || SpbPaginatorSv);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SpbPaginatorSv,
      factory: SpbPaginatorSv.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SpbPaginatorSv, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
const WINDOW = new InjectionToken('window');
class LicensesDialogComponent {
  static {
    this.ɵfac = function LicensesDialogComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LicensesDialogComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LicensesDialogComponent,
      selectors: [["spb-licenses-dialog"]],
      decls: 12,
      vars: 1,
      consts: [["matDialogTitle", ""], ["matDialogContent", ""], ["data", i0.ɵɵtrustConstantResourceUrl`/3rdpartylicenses.txt`, "height", "400", "type", "text/plain", "width", "500"], ["href", "/3rdpartylicenses.txt"], ["mat-dialog-actions", ""], ["mat-raised-button", "", 3, "mat-dialog-close"]],
      template: function LicensesDialogComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtext(1, "Tredjepartsprogramvara");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(2, "div", 1)(3, "p");
          i0.ɵɵtext(4, "Denna mjukvara anv\xE4nder sig av mjukvara fr\xE5n tredje part.");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(5, "div")(6, "object", 2)(7, "a", 3);
          i0.ɵɵtext(8, "Tredjepartsprogramvara");
          i0.ɵɵelementEnd()()()();
          i0.ɵɵelementStart(9, "div", 4)(10, "button", 5);
          i0.ɵɵtext(11, "St\xE4ng ");
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance(10);
          i0.ɵɵproperty("mat-dialog-close", undefined);
        }
      },
      dependencies: [MatDialogTitle, MatDialogContent, MatDialogActions, MatButton, MatDialogClose],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LicensesDialogComponent, [{
    type: Component,
    args: [{
      selector: 'spb-licenses-dialog',
      imports: [MatDialogTitle, CdkScrollable, MatDialogContent, MatDialogActions, MatButton, MatDialogClose],
      template: "<div matDialogTitle>Tredjepartsprogramvara</div>\n<div matDialogContent>\n  <p>Denna mjukvara anv\u00E4nder sig av mjukvara fr\u00E5n tredje part.</p>\n\n  <div>\n    <object data=\"/3rdpartylicenses.txt\" height=\"400\"\n            type=\"text/plain\" width=\"500\">\n      <a href=\"/3rdpartylicenses.txt\">Tredjepartsprogramvara</a>\n    </object>\n  </div>\n</div>\n<div mat-dialog-actions>\n  <button\n    [mat-dialog-close]=\"undefined\"\n    mat-raised-button>St\u00E4ng\n  </button>\n</div>\n"
    }]
  }], null, null);
})();
class VersionService {
  constructor(http) {
    this.http = http;
    this.version$ = new ReplaySubject(1);
    this.cancel$ = new Subject();
  }
  start() {
    timer(0, 60 * 5 * 1000) // We check every 5 minutes
    .pipe(takeUntil(this.cancel$), switchMap(() => this.http.get(`/assets/package.json?ts=${Date.now()}`))).subscribe({
      next: res => {
        this.version$.next(res.version);
      }
    });
  }
  cancel() {
    this.cancel$.next({});
  }
  static {
    this.ɵfac = function VersionService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || VersionService)(i0.ɵɵinject(i1.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: VersionService,
      factory: VersionService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(VersionService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class SettingsService {
  constructor(httpClient) {
    this.httpClient = httpClient;
    /**
     * Consumers should listen to this to get the latest setting.
     */
    this.setting$ = new BehaviorSubject({});
  }
  /**
   * Get a setting from database
   */
  getSetting(settingsServiceUrl) {
    const url = `${settingsServiceUrl}/settings`;
    return this.httpClient.get(url, {
      withCredentials: true
    }).pipe(map(settingItem => {
      this.setting$.next(settingItem);
      return settingItem;
    }));
  }
  /**
   * Save or update a setting to database
   */
  updateSetting(settingsServiceUrl, setting) {
    const url = `${settingsServiceUrl}/settings`;
    return this.httpClient.put(url, setting, {
      withCredentials: true
    }).pipe(map(settingItem => {
      this.setting$.next(settingItem);
      return settingItem;
    }));
  }
  static {
    this.ɵfac = function SettingsService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SettingsService)(i0.ɵɵinject(i1.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SettingsService,
      factory: SettingsService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SettingsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class FooterComponent {
  constructor(dialog, versionService, settingsService, snackBar) {
    this.dialog = dialog;
    this.versionService = versionService;
    this.settingsService = settingsService;
    this.snackBar = snackBar;
    /**
     * This should be the current release of the user application.
     * It is used to warn if the loaded version differs from the
     * currently deployed version on _the same_ url.
     *
     * See version service for information.
     */
    this.version = 'x.y.x';
    /**
     * Url to settings backend
     */
    this.settingsServiceUrl = '';
    /**
     * The copyright string. If the current year is not included
     * the current year will be appended. Make sure to _not_ add
     * . or comma . before or after.
     */
    this.copyrightYears = '2023';
    /**
     * Show link to 3rdpartylicenses.txt
     */
    this.license = null;
    /**
     * The accessToken, used to check if the user is logged in
     */
    this.token = '';
    /**
     * Tells the site if it should have dark or light theme
     */
    this.darkTheme = new EventEmitter();
    /**
     * Emits true if the user should be logged out
     */
    this.logoutUser = new EventEmitter();
    /**
     * Injects the `WINDOW` token to access the browser's window object.
     */
    this.injectedWindow = inject(WINDOW);
    /**
     * Store a timer subscription.
     */
    this.timerSubscription = new Subscription();
    // Per default, we start the version check and check every 10 minutes.
    this.versionService.start();
  }
  /**
   * Open a dialog with 3rdpartylicensesp
   */
  openDialog() {
    const data = {};
    this.dialog.open(LicensesDialogComponent, {
      data
    });
  }
  ngOnInit() {
    /**
     * Just look for settings if we get an input to the settings backend
     */
    if (this.settingsServiceUrl) {
      this.settingsService.getSetting(this.settingsServiceUrl).pipe(filter(s => s && !!s.itemId)).subscribe({
        next: settings => this.darkTheme.emit(settings.darkTheme)
      });
    }
  }
  ngOnChanges() {
    const thisYear = new Date().getFullYear();
    if (this.copyrightYears.indexOf(thisYear + '') === -1) {
      this.copyrightYears += `, ${thisYear}`;
      this.copyrightYears = this.copyrightYears.replace(/^, /, '');
    }
    this.versionService.version$.pipe(switchMap(version => {
      let res = of({
        dismissedByAction: false
      });
      if (this.version !== version) {
        const ref = this.snackBar.open('Det finns en ny version', 'Uppdatera', {
          horizontalPosition: 'right',
          verticalPosition: 'top'
        });
        res = ref.afterDismissed();
      }
      return res;
    }), filter(res => res.dismissedByAction)).subscribe({
      next: () => {
        this.injectedWindow.location.reload();
      }
    });
    /**
     * Decode the token and check if the session is about to expire, but first stop the timer, if the is any timer.
     */
    this.stopTimer();
    if (this.token) {
      this.decodeToken(this.token);
    }
  }
  /**
   * Decode the accessToken and check if the session is about to expire
   */
  decodeToken(jwt) {
    const sessionTimeLeft = 60 * 5 * 1000; // 5 minutes
    let expireTime;
    try {
      const jwtPayload = jwt.split('.')[1];
      const decodedPayload = window.atob(jwtPayload);
      const payload = JSON.parse(decodedPayload);
      expireTime = payload.exp;
    } catch (_e) {
      // If not a valid token, let us just skip this.
      return;
    }
    this.timerSubscription = timer(0, sessionTimeLeft).subscribe({
      next: () => {
        /**
         * Logout the user if the session has expired
         */
        if (Date.now() > expireTime) {
          this.stopTimer();
          this.snackBar.dismiss();
          this.logoutUser.emit(true);
        } else if (Date.now() > expireTime - sessionTimeLeft) {
          /**
           * Give user a warning if the session is about to expire
           */
          this.showExpiredSessionSnackbar();
        }
      }
    });
  }
  stopTimer() {
    this.timerSubscription.unsubscribe();
  }
  /**
   * Show a snackbar to the user if the session is about to expire
   * @private
   */
  showExpiredSessionSnackbar() {
    this.snackBar.open('Din session går ut snart. Vänligen logga in igen.', 'Logga ut', {
      horizontalPosition: 'right',
      verticalPosition: 'top'
    }).afterDismissed().pipe(filter(res => res.dismissedByAction)).subscribe({
      next: () => {
        /**
         * Logout the user
         */
        this.logoutUser.emit(true);
        this.stopTimer();
      }
    });
  }
  static {
    this.ɵfac = function FooterComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FooterComponent)(i0.ɵɵdirectiveInject(i1$1.MatDialog), i0.ɵɵdirectiveInject(VersionService), i0.ɵɵdirectiveInject(SettingsService), i0.ɵɵdirectiveInject(i4.MatSnackBar));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: FooterComponent,
      selectors: [["spb-footer"]],
      inputs: {
        version: "version",
        settingsServiceUrl: "settingsServiceUrl",
        copyrightYears: "copyrightYears",
        license: "license",
        token: "token"
      },
      outputs: {
        darkTheme: "darkTheme",
        logoutUser: "logoutUser"
      },
      features: [i0.ɵɵProvidersFeature([{
        provide: WINDOW,
        useValue: window
      }]), i0.ɵɵNgOnChangesFeature],
      decls: 8,
      vars: 3,
      consts: [[1, "contact"], [1, "pointer"], [1, "filler"], [1, "bottom-foot"], [1, "pointer", 3, "click"]],
      template: function FooterComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtemplate(1, FooterComponent_Conditional_1_Template, 2, 0, "div", 1);
          i0.ɵɵelement(2, "div", 2);
          i0.ɵɵelementStart(3, "div");
          i0.ɵɵtext(4);
          i0.ɵɵelementEnd()();
          i0.ɵɵelementStart(5, "div", 3)(6, "div");
          i0.ɵɵtext(7);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.license ? 1 : -1);
          i0.ɵɵadvance(3);
          i0.ɵɵtextInterpolate1("v", ctx.version, "");
          i0.ɵɵadvance(3);
          i0.ɵɵtextInterpolate1("All Rights Reserved Sparbanken Syd \xA9 ", ctx.copyrightYears, "");
        }
      },
      styles: [".contact[_ngcontent-%COMP%]{background-color:#fedd46;color:#fedd46;display:flex;justify-content:space-between;margin-top:50px;min-height:20px;width:100%}.contact[_ngcontent-%COMP%]   .pointer[_ngcontent-%COMP%]{cursor:pointer}.filler[_ngcontent-%COMP%]{flex-grow:1}.bottom-foot[_ngcontent-%COMP%]{background-color:#000;border-top:1px solid #fedd46;color:#9a9a9a;display:flex;flex-direction:column;font-size:14px;height:90px;justify-content:center;margin:0;text-transform:uppercase;width:100%}.bottom-foot[_ngcontent-%COMP%] > div[_ngcontent-%COMP%]{text-align:center;margin:0 10px}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FooterComponent, [{
    type: Component,
    args: [{
      selector: 'spb-footer',
      imports: [],
      providers: [{
        provide: WINDOW,
        useValue: window
      }],
      template: "<div class=\"contact\">\n  @if (license) {\n    <div class=\"pointer\" (click)=\"openDialog()\">Tredjepartsprogramvara</div>\n  }\n  <div class=\"filler\"></div>\n  <div>v{{ version }}</div>\n</div>\n\n<div class=\"bottom-foot\">\n  <div>All Rights Reserved Sparbanken Syd \u00A9 {{ copyrightYears }}</div>\n</div>\n",
      styles: [".contact{background-color:#fedd46;color:#fedd46;display:flex;justify-content:space-between;margin-top:50px;min-height:20px;width:100%}.contact .pointer{cursor:pointer}.filler{flex-grow:1}.bottom-foot{background-color:#000;border-top:1px solid #fedd46;color:#9a9a9a;display:flex;flex-direction:column;font-size:14px;height:90px;justify-content:center;margin:0;text-transform:uppercase;width:100%}.bottom-foot>div{text-align:center;margin:0 10px}\n"]
    }]
  }], () => [{
    type: i1$1.MatDialog
  }, {
    type: VersionService
  }, {
    type: SettingsService
  }, {
    type: i4.MatSnackBar
  }], {
    version: [{
      type: Input
    }],
    settingsServiceUrl: [{
      type: Input
    }],
    copyrightYears: [{
      type: Input
    }],
    license: [{
      type: Input
    }],
    token: [{
      type: Input
    }],
    darkTheme: [{
      type: Output
    }],
    logoutUser: [{
      type: Output
    }]
  });
})();
class LogoComponent {
  constructor() {
    /**
     * Add internal paths like ['/', 'login'] etc.
     * leave it to route to ['/']
     */
    this.routePath = ['/'];
  }
  static {
    this.ɵfac = function LogoComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LogoComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LogoComponent,
      selectors: [["spb-logo"]],
      inputs: {
        routePath: "routePath",
        title: "title",
        onlyLogoLink: "onlyLogoLink"
      },
      decls: 7,
      vars: 2,
      consts: [["image", ""], [1, "spb-logo-holder"], [3, "routerLink"], ["href", "https://sparbankensyd.se", 1, "public-link"], [4, "ngTemplateOutlet"], [3, "routerLink", 4, "ngTemplateOutlet"], [1, "admin-site", 3, "routerLink"], ["alt", "Sparbanken Syd logo", "src", "../../../assets/sparbanken_syd_logo.svg", 1, "spb-logo", 3, "routerLink"]],
      template: function LogoComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 1);
          i0.ɵɵtemplate(1, LogoComponent_Conditional_1_Template, 1, 1, "ng-container")(2, LogoComponent_Conditional_2_Template, 1, 1, "ng-container", 2)(3, LogoComponent_Conditional_3_Template, 2, 1, "a", 3)(4, LogoComponent_Conditional_4_Template, 4, 2);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(5, LogoComponent_ng_template_5_Template, 1, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.title ? 1 : ctx.onlyLogoLink ? 2 : 3);
          i0.ɵɵadvance(3);
          i0.ɵɵconditional(ctx.title ? 4 : -1);
        }
      },
      dependencies: [NgTemplateOutlet, RouterLink, MatIcon],
      styles: [".spb-logo-holder[_ngcontent-%COMP%]{display:flex;align-items:center;justify-content:flex-start;margin-left:16px;gap:5px}.spb-logo-holder[_ngcontent-%COMP%]   .spb-logo[_ngcontent-%COMP%]{width:170px}.spb-logo-holder[_ngcontent-%COMP%]   .public-link[_ngcontent-%COMP%]{line-height:0;text-decoration:none}.spb-logo-holder[_ngcontent-%COMP%]   .admin-site[_ngcontent-%COMP%]{font-size:18px;padding:5px 10px;background-color:transparent;border-radius:5px;border:1px solid #fedd46}.spb-logo-holder[_ngcontent-%COMP%]   .spb-logo[_ngcontent-%COMP%]:hover, .spb-logo-holder[_ngcontent-%COMP%]   .admin-site[_ngcontent-%COMP%]:hover{cursor:pointer}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LogoComponent, [{
    type: Component,
    args: [{
      selector: 'spb-logo',
      imports: [NgTemplateOutlet, RouterLink, MatIcon],
      template: "<div class=\"spb-logo-holder\">\n  @if (title) {\n    <ng-container *ngTemplateOutlet=\"image\"></ng-container>\n  } @else if (onlyLogoLink){\n    <ng-container *ngTemplateOutlet=\"image\" [routerLink]=\"routePath\"></ng-container>\n  } @else {\n    <a class=\"public-link\" href=\"https://sparbankensyd.se\">\n      <ng-container *ngTemplateOutlet=\"image\"></ng-container>\n    </a>\n  }\n  @if (title) {\n    <mat-icon>chevron_right</mat-icon>\n    <div class=\"admin-site\" [routerLink]=\"routePath\">{{ title }}</div>\n  }\n</div>\n\n<ng-template #image>\n  <img [routerLink]=\"routePath\"\n       alt=\"Sparbanken Syd logo\"\n       class=\"spb-logo\"\n       src=\"../../../assets/sparbanken_syd_logo.svg\">\n</ng-template>",
      styles: [".spb-logo-holder{display:flex;align-items:center;justify-content:flex-start;margin-left:16px;gap:5px}.spb-logo-holder .spb-logo{width:170px}.spb-logo-holder .public-link{line-height:0;text-decoration:none}.spb-logo-holder .admin-site{font-size:18px;padding:5px 10px;background-color:transparent;border-radius:5px;border:1px solid #fedd46}.spb-logo-holder .spb-logo:hover,.spb-logo-holder .admin-site:hover{cursor:pointer}\n"]
    }]
  }], null, {
    routePath: [{
      type: Input
    }],
    title: [{
      type: Input
    }],
    onlyLogoLink: [{
      type: Input
    }]
  });
})();
class SettingsComponent {
  constructor(settingsService, dialogRef, data) {
    this.settingsService = settingsService;
    this.dialogRef = dialogRef;
    this.data = data;
    this.darkTheme = false;
    this.setting = {
      darkTheme: false,
      itemId: '',
      timeStamp: 0,
      version: 0
    };
    this.selectedTheme = '';
  }
  ngOnInit() {
    this.darkTheme = this.data.darkTheme;
    /**
     * Show dark or light in selector
     */
    this.selectedTheme = this.data.darkTheme ? 'dark' : 'light';
  }
  changeTheme(event) {
    this.darkTheme = event === 'dark';
  }
  save() {
    this.settingsService.setting$.pipe(first(), filter(s => !!s)).subscribe({
      next: setting => this.setting = setting
    });
    this.setting.darkTheme = this.darkTheme;
    this.settingsService.updateSetting(this.data.settingsServiceUrl, this.setting).subscribe({
      next: value => this.dialogRef.close(value.darkTheme)
    });
  }
  static {
    this.ɵfac = function SettingsComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SettingsComponent)(i0.ɵɵdirectiveInject(SettingsService), i0.ɵɵdirectiveInject(i1$1.MatDialogRef), i0.ɵɵdirectiveInject(MAT_DIALOG_DATA));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SettingsComponent,
      selectors: [["spb-settings"]],
      decls: 16,
      vars: 4,
      consts: [["matDialogTitle", ""], ["matDialogContent", ""], [3, "selectionChange", "value"], [3, "value"], ["mat-dialog-actions", ""], ["mat-raised-button", "", "color", "primary", 3, "click"], ["mat-raised-button", "", 3, "mat-dialog-close"]],
      template: function SettingsComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtext(1, "Inst\xE4llningar");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(2, "div", 1)(3, "mat-form-field")(4, "mat-label");
          i0.ɵɵtext(5, "V\xE4lj temal\xE4ge");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(6, "mat-select", 2);
          i0.ɵɵlistener("selectionChange", function SettingsComponent_Template_mat_select_selectionChange_6_listener($event) {
            return ctx.changeTheme($event.value);
          });
          i0.ɵɵelementStart(7, "mat-option", 3);
          i0.ɵɵtext(8, "Ljust l\xE4ge");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(9, "mat-option", 3);
          i0.ɵɵtext(10, "M\xF6rkt l\xE4ge");
          i0.ɵɵelementEnd()()()();
          i0.ɵɵelementStart(11, "div", 4)(12, "button", 5);
          i0.ɵɵlistener("click", function SettingsComponent_Template_button_click_12_listener() {
            return ctx.save();
          });
          i0.ɵɵtext(13, " Spara");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(14, "button", 6);
          i0.ɵɵtext(15, "Avbryt");
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance(6);
          i0.ɵɵproperty("value", ctx.selectedTheme);
          i0.ɵɵadvance();
          i0.ɵɵproperty("value", "light");
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("value", "dark");
          i0.ɵɵadvance(5);
          i0.ɵɵproperty("mat-dialog-close", undefined);
        }
      },
      dependencies: [MatDialogTitle, MatDialogContent, MatFormField, MatLabel, MatSelect, MatOption, MatDialogActions, MatButton, MatDialogClose],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SettingsComponent, [{
    type: Component,
    args: [{
      selector: 'spb-settings',
      imports: [MatDialogTitle, CdkScrollable, MatDialogContent, MatFormField, MatLabel, MatSelect, MatOption, MatDialogActions, MatButton, MatDialogClose],
      template: "<div matDialogTitle>Inst\u00E4llningar</div>\n\n<div matDialogContent>\n\n  <mat-form-field>\n    <mat-label>V\u00E4lj temal\u00E4ge</mat-label>\n    <mat-select [value]=\"selectedTheme\" (selectionChange)=\"changeTheme($event.value)\">\n      <mat-option [value]=\"'light'\">Ljust l\u00E4ge</mat-option>\n      <mat-option [value]=\"'dark'\">M\u00F6rkt l\u00E4ge</mat-option>\n    </mat-select>\n  </mat-form-field>\n\n</div>\n\n<div mat-dialog-actions>\n  <button mat-raised-button color=\"primary\" (click)=\"save()\"> Spara</button>\n  <button [mat-dialog-close]=\"undefined\" mat-raised-button>Avbryt</button>\n</div>\n"
    }]
  }], () => [{
    type: SettingsService
  }, {
    type: i1$1.MatDialogRef
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [MAT_DIALOG_DATA]
    }]
  }], null);
})();

/**
 * Use this to get formatted numbers as "20 000 000". When doing
 * so make sure your input is "text" but expect numbers back.
 */
class FormatNumberDirective {
  /**
   * The element as injected.
   */
  constructor(elementRef) {
    this.elementRef = elementRef;
    /**
     * Must always be a number. This will hold the value
     * w/o spaces.
     */
    this.pValue = 0;
    /**
     * Placeholders for the callbacks which are later provided
     */
    this.onTouched = noop;
    this.onChange = noop;
  }
  static detectDecimalValues(value) {
    const values = [',', '.'];
    let haveValue = false;
    values.forEach(comma => {
      if (value.indexOf(comma) !== -1) {
        haveValue = true;
      }
    });
    return haveValue;
  }
  /**
   *
   * This is called on every input in the input
   */
  onInput(value) {
    this.formatValue(value); // Sets the display value to 100 000 etc.
    this.unFormatValue(); // Sets pValue to a string w/o spaces.
    this.onChange(this.pValue); // Pass along to other validators.
  }
  onBlur() {
    this.onTouched(this.pValue);
  }
  /**
   * Called from the outside, when the value is set
   * from code, not from edits in the form. Important
   * we make the assumption that this is always
   * numbers.
   */
  writeValue(value) {
    if (value === 0) {
      this.elementRef.nativeElement.value = '';
    } else {
      this.formatValue(value);
    }
  }
  /**
   * Replaces the onChanege and onTouched
   */
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  /**
   * Set "our" value to 10000
   */
  unFormatValue() {
    const value = this.elementRef.nativeElement.value;
    // Convert decimal ',' to '.' and remove spaces.
    this.pValue = +value.replace(',', '.').replace(/[^\d.-]/g, '');
  }
  /**
   * Set the element to "100 000" format.
   */
  formatValue(val) {
    let value = val + ''; // If a number coerce to string
    value = value.replace(/[^\d,.-]/g, '');
    if (value === '') {
      this.elementRef.nativeElement.value = value;
    } else if (FormatNumberDirective.detectDecimalValues(value)) {
      value = value.replace(',', '.');
      this.elementRef.nativeElement.value = formatNumber(+value, 'fr', '1.2-2');
    } else {
      this.elementRef.nativeElement.value = formatNumber(+value, 'fr', '1.0-0');
    }
  }
  static {
    this.ɵfac = function FormatNumberDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FormatNumberDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: FormatNumberDirective,
      selectors: [["input", "spbFormatNumber", ""]],
      hostBindings: function FormatNumberDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("input", function FormatNumberDirective_input_HostBindingHandler($event) {
            return ctx.onInput($event.target.value);
          })("blur", function FormatNumberDirective_blur_HostBindingHandler() {
            return ctx.onBlur();
          });
        }
      },
      features: [i0.ɵɵProvidersFeature([{
        provide: MAT_INPUT_VALUE_ACCESSOR,
        useExisting: FormatNumberDirective
      }, {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => FormatNumberDirective),
        multi: true
      }])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormatNumberDirective, [{
    type: Directive,
    args: [{
      selector: 'input[spbFormatNumber]',
      providers: [{
        provide: MAT_INPUT_VALUE_ACCESSOR,
        useExisting: FormatNumberDirective
      }, {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => FormatNumberDirective),
        multi: true
      }],
      standalone: true
    }]
  }], () => [{
    type: i0.ElementRef
  }], {
    onInput: [{
      type: HostListener,
      args: ['input', ['$event.target.value']]
    }],
    onBlur: [{
      type: HostListener,
      args: ['blur']
    }]
  });
})();

/**
 * Use this to format Sparbanken Syd account numbers
 * to 123 123 123 1 format.
 *
 * Supports clearing number by default (9570 123 123 123 1)
 *
 */
class FormatAccountDirective {
  /**
   * The element as injected.
   */
  constructor(elementRef) {
    this.elementRef = elementRef;
    /**
     * If set to true the clearing number is
     * considered non-existing and will not
     * be used in formatting.
     */
    this.skipClearing = false;
    /**
     * Always a string
     */
    this.pValue = '';
    /**
     * Placeholders for the callbacks which are later provided
     */
    this.onTouched = noop;
    this.onChange = noop;
  }
  /**
   *
   * This is called on every input in the input
   */
  onInput(value, target) {
    this.formatValue(value, target.selectionStart || 0, this.skipClearing ? 13 : 18); // Sets the display value to 123 123 123 1 etc.
    this.unFormatValue(); // Sets pValue to a string w/o spaces.
    this.onChange(this.pValue); // Pass along to other validators.
  }
  onBlur() {
    this.onTouched(this.pValue);
  }
  /**
   * Called from the outside, when the value is set
   * from code, not from edits in the form. Important
   * we make the assumption that this is always
   * numbers.
   */
  writeValue(value) {
    this.formatValue(value, value.length, this.skipClearing ? 13 : 18);
  }
  /**
   * Replaces the onChange and onTouched
   */
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  /**
   * Set "our" value to 10000
   */
  unFormatValue() {
    const value = this.elementRef.nativeElement.value;
    // Replace anything not a digit
    this.pValue = value.replace(/\D/g, '');
  }
  /**
   * Set the element to "100 000" format.
   */
  formatValue(val, start, maxLength) {
    const wsIn = (val.match(/\s/g) || []).length;
    const value = formatAccountNumber(val, this.skipClearing);
    const wsOut = (value.match(/\s/g) || []).length;
    const position = start + Math.max(wsOut - wsIn, 0);
    // 18 is max 14 digits + 4 spaces 9570 123 123 123 0
    this.elementRef.nativeElement.value = value.substring(0, maxLength);
    this.elementRef.nativeElement.selectionStart = position;
    this.elementRef.nativeElement.selectionEnd = position;
  }
  static {
    this.ɵfac = function FormatAccountDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FormatAccountDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: FormatAccountDirective,
      selectors: [["input", "spbFormatAccount", ""]],
      hostBindings: function FormatAccountDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("input", function FormatAccountDirective_input_HostBindingHandler($event) {
            return ctx.onInput($event.target.value, $event.target);
          })("blur", function FormatAccountDirective_blur_HostBindingHandler() {
            return ctx.onBlur();
          });
        }
      },
      inputs: {
        skipClearing: "skipClearing"
      },
      features: [i0.ɵɵProvidersFeature([{
        provide: MAT_INPUT_VALUE_ACCESSOR,
        useExisting: FormatAccountDirective
      }, {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => FormatAccountDirective),
        multi: true
      }])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormatAccountDirective, [{
    type: Directive,
    args: [{
      selector: 'input[spbFormatAccount]',
      providers: [{
        provide: MAT_INPUT_VALUE_ACCESSOR,
        useExisting: FormatAccountDirective
      }, {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => FormatAccountDirective),
        multi: true
      }],
      standalone: true
    }]
  }], () => [{
    type: i0.ElementRef
  }], {
    skipClearing: [{
      type: Input
    }],
    onInput: [{
      type: HostListener,
      args: ['input', ['$event.target.value', '$event.target']]
    }],
    onBlur: [{
      type: HostListener,
      args: ['blur']
    }]
  });
})();
const formatAccountNumber = (input, skipClearing) => {
  // Replace anything that's not a digit
  let value = (input + '').replace(/\D/g, '');
  const valueLength = skipClearing ? 10 : 14;
  const accountStart = skipClearing ? 0 : 4;
  while (value.length < valueLength) {
    value += ' ';
  }
  const clearNo = skipClearing ? '' : value.substring(0, 4) + ' ';
  const grp1 = value.substring(accountStart, accountStart + 3) + ' '; // 4, 7, or 0, 3
  const grp2 = value.substring(accountStart + 3, accountStart + 6) + ' '; // 7, 10 or 3, 6
  const grp3 = value.substring(accountStart + 6, accountStart + 9) + ' '; // 10, 13, 9, 12
  value = (clearNo + grp1 + grp2 + grp3 + value.substring(accountStart + 9)).trim();
  return value;
};
class StepperScrollDirective {
  constructor(stepper) {
    this.stepper = stepper;
  }
  selectionChanged(selection) {
    const stepId = this.stepper._getStepLabelId(selection.selectedIndex - 1);
    this.scrollElement(stepId);
  }
  scrollElement(elemId) {
    const stepElement = document.getElementById(elemId);
    stepElement?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'center'
    });
  }
  static {
    this.ɵfac = function StepperScrollDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || StepperScrollDirective)(i0.ɵɵdirectiveInject(i1$2.MatStepper));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: StepperScrollDirective,
      selectors: [["", "spbStepperScroll", ""]],
      hostBindings: function StepperScrollDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("selectionChange", function StepperScrollDirective_selectionChange_HostBindingHandler($event) {
            return ctx.selectionChanged($event);
          });
        }
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StepperScrollDirective, [{
    type: Directive,
    args: [{
      selector: '[spbStepperScroll]'
    }]
  }], () => [{
    type: i1$2.MatStepper
  }], {
    selectionChanged: [{
      type: HostListener,
      args: ['selectionChange', ['$event']]
    }]
  });
})();
const DEFAULT_ERROR_MESSAGE = 'Vänligen kontrollera så att personnumret stämmer';
const DUPLICATED_ERROR_MESSAGE = 'Personnumret kan inte användas två gånger';
const UNDERAGE_ERROR_MESSAGE = 'Du måste vara över 18 år för att få ansöka om lån i Sparbanken Syd';
const CANNOT_BE_ORG_ERROR_MESSAGE = 'Det kan inte vara ett organisationsnummer';
const validatePersonnummerControl = (control, mustBeOver18, otherPersonnummers, canBeOrg) => {
  if (control.value === '' || control.value === null) {
    return null;
  }
  // If Personnummer is less than 10 characters there is no need to check it
  if (control.value.length < 10) {
    return {
      error: '',
      isDuplicated: false,
      isUnderage: false
    };
  }
  try {
    const response = verify(control.value);
    // Check if the personnummer can be an organisationsnummer too
    if (!canBeOrg && response.isOrg) {
      return {
        error: CANNOT_BE_ORG_ERROR_MESSAGE,
        isDuplicated: false,
        isUnderage: false
      };
    }
    // Set the form control's value to the validated value only if it's different
    if (control.value !== response['13']) {
      control.setValue(response['13'], {
        onlySelf: true,
        emitEvent: false
      });
    }
    // Check duplicated Personnummers (if any to check)
    if (otherPersonnummers.some(otherPersonnummer => otherPersonnummer?.replace('-', '') === control.value.replace('-', ''))) {
      return {
        error: DUPLICATED_ERROR_MESSAGE,
        isDuplicated: true,
        isUnderage: false
      };
    }
    // Check +18 if necessary
    // (only if it is a personnummer, not an organisationsnummer)
    if (!response.isOrg && mustBeOver18 && response.age < 18) {
      return {
        error: UNDERAGE_ERROR_MESSAGE,
        isDuplicated: false,
        isUnderage: true
      };
    }
    // All OK, no errors.
    return null;
  } catch (err) {
    return {
      error: DEFAULT_ERROR_MESSAGE,
      isDuplicated: false,
      isUnderage: false
    };
  }
};
class PersonnummerValidatorDirective {
  constructor() {
    this.mustBeOver18 = false;
    this.otherPersonnummers = [];
    this.canBeOrg = false;
  }
  validate(control) {
    return validatePersonnummerControl(control, this.mustBeOver18, this.otherPersonnummers, this.canBeOrg);
  }
  static {
    this.ɵfac = function PersonnummerValidatorDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PersonnummerValidatorDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: PersonnummerValidatorDirective,
      selectors: [["", "spbPersonnummerValidator", ""]],
      inputs: {
        mustBeOver18: "mustBeOver18",
        otherPersonnummers: "otherPersonnummers",
        canBeOrg: "canBeOrg"
      },
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALIDATORS,
        useExisting: PersonnummerValidatorDirective,
        multi: true
      }])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PersonnummerValidatorDirective, [{
    type: Directive,
    args: [{
      selector: '[spbPersonnummerValidator]',
      providers: [{
        provide: NG_VALIDATORS,
        useExisting: PersonnummerValidatorDirective,
        multi: true
      }],
      standalone: true
    }]
  }], null, {
    mustBeOver18: [{
      type: Input
    }],
    otherPersonnummers: [{
      type: Input
    }],
    canBeOrg: [{
      type: Input
    }]
  });
})();

/**
 * Use this to format Sparbanken Syd account numbers
 * to 123 123 123 1 format.
 *
 * Supports clearing number by default (9570 123 123 123 1)
 *
 */
class FormatAccountPipe {
  transform(value, skipClearing = false) {
    return formatAccountNumber(value, skipClearing);
  }
  static {
    this.ɵfac = function FormatAccountPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FormatAccountPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "spbFormatAccount",
      type: FormatAccountPipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormatAccountPipe, [{
    type: Pipe,
    args: [{
      name: 'spbFormatAccount',
      standalone: true
    }]
  }], null, null);
})();

/**
 * Validates a Sparbanken Syd account number. 10 digits.
 * Luhn 10 modulus is used
 * Skipped if length != 10
 *
 * @param control - Any control with a string value.
 */
const accountNoValidator = control => {
  let val = control.value;
  while (val.length < 10) {
    val = '0' + val;
  }
  const arr = [...val];
  const last = Number.parseInt(arr.pop(), 10);
  const luhn = calculate(arr);
  if (luhn === last) {
    return null;
  }
  return {
    error: 'Kontrollfel: ' + luhn
  };
};
const calculate = numbers => {
  let sum = 0;
  const multipliers = [2, 1];
  do {
    const num = Number.parseInt(numbers.pop(), 10);
    let product = num * multipliers[0];
    multipliers.reverse();
    if (product > 9) {
      product = product - 9;
    }
    sum += product;
  } while (numbers.length > 0);
  return sum % 10 ? 10 - sum % 10 : 0;
};

/**
 * Validates a personal identification number (personnummer) using our personnummer lib
 * If the provided value is a valid personal identification number, the function sets the form control's value
 * If the value is not a valid personal identification number, an error is returned with the error message.
 *
 * @param control - The form control to be validated.
 * @returns A ValidationErrors object if the value is invalid, otherwise null.
 */
const pnrValidator = control => {
  return validatePersonnummerControl(control, false, [], false);
};

/**
 * Validates a personal identification number (personnummer) using our
 * personnummer lib.
 * If the provided value is a valid personal identification number,
 * the function sets the form control's value
 * If the value is not a valid personal identification number,
 * an error is returned with the error message.
 *
 * @param mustBeOver18 - Optional flag to check if the introduced personnummer
 * must be of an adult (+18 years old).
 * @param otherPersonnummers - Optional personnummers array to check from in
 * order to not repeat them.
 * @param canBeOrg - Optional flag to allow organisationsnummer too
 * @returns A ValidationErrors object if the value is invalid, otherwise null.
 */
const pnrValidatorExtended = (mustBeOver18 = false, otherPersonnummers = [], canBeOrg = false) => {
  return control => {
    return validatePersonnummerControl(control, mustBeOver18, otherPersonnummers, canBeOrg);
  };
};

/**
 * A class containing constants used across applications.
 * Provides scoping for variables.
 */
class SPBConstants {
  /**
   * A  list of countries with their corresponding codes.
   * Each country includes:
   * - `name`: The name of the country.
   * - `2`: The ISO 3166-1 alpha-2 code (two-character country code, e.g., 'AF' for Afghanistan).
   * - `3`: The ISO 3166-1 alpha-3 code (three-character country code, e.g., 'AFG' for Afghanistan).
   * - `n`: The ISO 3166-1 numeric-3 code (three-digit numeric code, e.g., 4 for Afghanistan).
   */
  static {
    this.CountryList = [{
      name: 'Afghanistan',
      2: 'AF',
      3: 'AFG',
      n: 4
    }, {
      name: 'Albanien',
      2: 'AL',
      3: 'ALB',
      n: 8
    }, {
      name: 'Algeriet',
      2: 'DZ',
      3: 'DZA',
      n: 12
    }, {
      name: 'Amerikanska Samoa',
      2: 'AS',
      3: 'ASM',
      n: 16
    }, {
      name: 'Andorra',
      2: 'AD',
      3: 'AND',
      n: 20
    }, {
      name: 'Angola',
      2: 'AO',
      3: 'AGO',
      n: 24
    }, {
      name: 'Anguilla',
      2: 'AI',
      3: 'AIA',
      n: 660
    }, {
      name: 'Antarktis',
      2: 'AQ',
      3: 'ATA',
      n: 10
    }, {
      name: 'Antigua och Barbuda',
      2: 'AG',
      3: 'ATG',
      n: 28
    }, {
      name: 'Argentina',
      2: 'AR',
      3: 'ARG',
      n: 32
    }, {
      name: 'Armenien',
      2: 'AM',
      3: 'ARM',
      n: 51
    }, {
      name: 'Aruba',
      2: 'AW',
      3: 'ABW',
      n: 533
    }, {
      name: 'Australien',
      2: 'AU',
      3: 'AUS',
      n: 36
    }, {
      name: 'Azerbajdzjan',
      2: 'AZ',
      3: 'AZE',
      n: 31
    }, {
      name: 'Bahamas',
      2: 'BS',
      3: 'BHS',
      n: 44
    }, {
      name: 'Bahrain',
      2: 'BH',
      3: 'BHR',
      n: 48
    }, {
      name: 'Bangladesh',
      2: 'BD',
      3: 'BGD',
      n: 50
    }, {
      name: 'Barbados',
      2: 'BB',
      3: 'BRB',
      n: 52
    }, {
      name: 'Belgien',
      2: 'BE',
      3: 'BEL',
      n: 56
    }, {
      name: 'Belize',
      2: 'BZ',
      3: 'BLZ',
      n: 84
    }, {
      name: 'Benin',
      2: 'BJ',
      3: 'BEN',
      n: 204
    }, {
      name: 'Bermuda',
      2: 'BM',
      3: 'BMU',
      n: 60
    }, {
      name: 'Bhutan',
      2: 'BT',
      3: 'BTN',
      n: 64
    }, {
      name: 'Bolivia',
      2: 'BO',
      3: 'BOL',
      n: 68
    }, {
      name: 'Bosnien-Hercegovina',
      2: 'BA',
      3: 'BIH',
      n: 70
    }, {
      name: 'Botswana',
      2: 'BW',
      3: 'BWA',
      n: 72
    }, {
      name: 'Bouvetön',
      2: 'BV',
      3: 'BVT',
      n: 74
    }, {
      name: 'Brasilien',
      2: 'BR',
      3: 'BRA',
      n: 76
    }, {
      name: 'Brittiska Jungfruöarna',
      2: 'VG',
      3: 'VGB',
      n: 92
    }, {
      name: 'Brittiska territoriet i Indiska Oceanen',
      2: 'IO',
      3: 'IOT',
      n: 86
    }, {
      name: 'Brunei',
      2: 'BN',
      3: 'BRN',
      n: 96
    }, {
      name: 'Bulgarien',
      2: 'BG',
      3: 'BGR',
      n: 100
    }, {
      name: 'Burkina Faso',
      2: 'BF',
      3: 'BFA',
      n: 854
    }, {
      name: 'Burma (Myanmar)',
      2: 'MM',
      3: 'MMR',
      n: 104
    }, {
      name: 'Burundi',
      2: 'BI',
      3: 'BDI',
      n: 108
    }, {
      name: 'Caymanöarna',
      2: 'KY',
      3: 'KYM',
      n: 136
    }, {
      name: 'Centralafrikanska republiken',
      2: 'CF',
      3: 'CAF',
      n: 140
    }, {
      name: 'Chile',
      2: 'CL',
      3: 'CHL',
      n: 152
    }, {
      name: 'Colombia',
      2: 'CO',
      3: 'COL',
      n: 170
    }, {
      name: 'Comorerna',
      2: 'KM',
      3: 'COM',
      n: 174
    }, {
      name: 'Cooköarna',
      2: 'CK',
      3: 'COK',
      n: 184
    }, {
      name: 'Costa Rica',
      2: 'CR',
      3: 'CRI',
      n: 188
    }, {
      name: 'Cypern',
      2: 'CY',
      3: 'CYP',
      n: 196
    }, {
      name: 'Danmark',
      2: 'DK',
      3: 'DNK',
      n: 208
    }, {
      name: 'Djibouti',
      2: 'DJ',
      3: 'DJI',
      n: 262
    }, {
      name: 'Dominica',
      2: 'DM',
      3: 'DMA',
      n: 212
    }, {
      name: 'Dominikanska republiken',
      2: 'DO',
      3: 'DOM',
      n: 214
    }, {
      name: 'Ecuador',
      2: 'EC',
      3: 'ECU',
      n: 218
    }, {
      name: 'Egypten',
      2: 'EG',
      3: 'EGY',
      n: 818
    }, {
      name: 'Ekvatorialguinea',
      2: 'GQ',
      3: 'GNQ',
      n: 226
    }, {
      name: 'El Salvador',
      2: 'SV',
      3: 'SLV',
      n: 222
    }, {
      name: 'Elfenbenskusten',
      2: 'CI',
      3: 'CIV',
      n: 384
    }, {
      name: 'Eritrea',
      2: 'ER',
      3: 'ERI',
      n: 232
    }, {
      name: 'Estland',
      2: 'EE',
      3: 'EST',
      n: 233
    }, {
      name: 'Etiopien',
      2: 'ET',
      3: 'ETH',
      n: 210
    }, {
      name: 'Falklandsöarna',
      2: 'FK',
      3: 'FLK',
      n: 238
    }, {
      name: 'Färöarna',
      2: 'FO',
      3: 'FRO',
      n: 234
    }, {
      name: 'Fiji',
      2: 'FJ',
      3: 'FJI',
      n: 242
    }, {
      name: 'Filippinerna',
      2: 'PH',
      3: 'PHL',
      n: 608
    }, {
      name: 'Finland',
      2: 'FI',
      3: 'FIN',
      n: 246
    }, {
      name: 'Frankrike',
      2: 'FR',
      3: 'FRA',
      n: 250
    }, {
      name: 'Frankrike (europeiska)',
      2: 'FX',
      3: 'FXX',
      n: 249
    }, {
      name: 'Franska Guyana',
      2: 'GF',
      3: 'GUF',
      n: 254
    }, {
      name: 'Franska Polynesien',
      2: 'PF',
      3: 'PYF',
      n: 258
    }, {
      name: 'Franska södra territorierna',
      2: 'TF',
      3: 'ATF',
      n: 260
    }, {
      name: 'Förenade arabemiraten',
      2: 'AE',
      3: 'ARE',
      n: 784
    }, {
      name: 'Gabon',
      2: 'GA',
      3: 'GAB',
      n: 266
    }, {
      name: 'Gambia',
      2: 'GM',
      3: 'GMB',
      n: 270
    }, {
      name: 'Georgien',
      2: 'GE',
      3: 'GEO',
      n: 268
    }, {
      name: 'Ghana',
      2: 'GH',
      3: 'GHA',
      n: 288
    }, {
      name: 'Gibraltar',
      2: 'GI',
      3: 'GIB',
      n: 292
    }, {
      name: 'Grönland',
      2: 'GL',
      3: 'GRL',
      n: 304
    }, {
      name: 'Grekland',
      2: 'GR',
      3: 'GRC',
      n: 300
    }, {
      name: 'Grenada',
      2: 'GD',
      3: 'GRD',
      n: 308
    }, {
      name: 'Guadeloupe',
      2: 'GP',
      3: 'GLP',
      n: 312
    }, {
      name: 'Guam',
      2: 'GU',
      3: 'GUM',
      n: 316
    }, {
      name: 'Guatemala',
      2: 'GT',
      3: 'GTM',
      n: 320
    }, {
      name: 'Guinea',
      2: 'GN',
      3: 'GIN',
      n: 324
    }, {
      name: 'Guinea Bissau',
      2: 'GW',
      3: 'GNB',
      n: 624
    }, {
      name: 'Guyana',
      2: 'GY',
      3: 'GUY',
      n: 328
    }, {
      name: 'Haiti',
      2: 'HT',
      3: 'HTI',
      n: 332
    }, {
      name: 'Heard- och McDonaldsöarna',
      2: 'HM',
      3: 'HMD',
      n: 334
    }, {
      name: 'Honduras',
      2: 'HN',
      3: 'HND',
      n: 340
    }, {
      name: 'Hongkong',
      2: 'HK',
      3: 'HKG',
      n: 344
    }, {
      name: 'Indien',
      2: 'IN',
      3: 'IND',
      n: 356
    }, {
      name: 'Indonesien',
      2: 'ID',
      3: 'IDN',
      n: 360
    }, {
      name: 'Irak',
      2: 'IQ',
      3: 'IRQ',
      n: 368
    }, {
      name: 'Iran',
      2: 'IR',
      3: 'IRN',
      n: 364
    }, {
      name: 'Irland',
      2: 'IE',
      3: 'IRL',
      n: 372
    }, {
      name: 'Island',
      2: 'IS',
      3: 'ISL',
      n: 352
    }, {
      name: 'Israel',
      2: 'IL',
      3: 'ISR',
      n: 376
    }, {
      name: 'Italien',
      2: 'IT',
      3: 'ITA',
      n: 380
    }, {
      name: 'Jamaica',
      2: 'JM',
      3: 'JAM',
      n: 388
    }, {
      name: 'Japan',
      2: 'JP',
      3: 'JPN',
      n: 392
    }, {
      name: 'Jordanien',
      2: 'JO',
      3: 'JOR',
      n: 400
    }, {
      name: 'Julön',
      2: 'CX',
      3: 'CXR',
      n: 162
    }, {
      name: 'Jungfruöarna USA',
      2: 'VI',
      3: 'VIR',
      n: 850
    }, {
      name: 'Kambodja',
      2: 'KH',
      3: 'KHM',
      n: 116
    }, {
      name: 'Kamerun',
      2: 'CM',
      3: 'CMR',
      n: 120
    }, {
      name: 'Kanada',
      2: 'CA',
      3: 'CAN',
      n: 124
    }, {
      name: 'Kap Verde',
      2: 'CV',
      3: 'CPV',
      n: 132
    }, {
      name: 'Kazakstan',
      2: 'KZ',
      3: 'KAZ',
      n: 398
    }, {
      name: 'Kenya',
      2: 'KE',
      3: 'KEN',
      n: 404
    }, {
      name: 'Kina',
      2: 'CN',
      3: 'CHN',
      n: 156
    }, {
      name: 'Kirgizistan',
      2: 'KG',
      3: 'KGZ',
      n: 417
    }, {
      name: 'Kiribati',
      2: 'KI',
      3: 'KIR',
      n: 296
    }, {
      name: 'Kokosöarna',
      2: 'CC',
      3: 'CCK',
      n: 166
    }, {
      name: 'Kongo',
      2: 'CG',
      3: 'COG',
      n: 178
    }, {
      name: 'Kosovo',
      2: 'XK',
      3: 'XXK',
      n: 900
    }, {
      name: 'Kroatien',
      2: 'HR',
      3: 'HRV',
      n: 191
    }, {
      name: 'Kuba',
      2: 'CU',
      3: 'CUB',
      n: 192
    }, {
      name: 'Kuwait',
      2: 'KW',
      3: 'KWT',
      n: 414
    }, {
      name: 'Laos',
      2: 'LA',
      3: 'LAO',
      n: 418
    }, {
      name: 'Lesotho',
      2: 'LS',
      3: 'LSO',
      n: 426
    }, {
      name: 'Lettland',
      2: 'LV',
      3: 'LVA',
      n: 428
    }, {
      name: 'Libanon',
      2: 'LB',
      3: 'LBN',
      n: 422
    }, {
      name: 'Liberia',
      2: 'LR',
      3: 'LBR',
      n: 430
    }, {
      name: 'Libyen',
      2: 'LY',
      3: 'LBY',
      n: 434
    }, {
      name: 'Liechtenstein',
      2: 'LI',
      3: 'LIE',
      n: 438
    }, {
      name: 'Litauen',
      2: 'LT',
      3: 'LTU',
      n: 440
    }, {
      name: 'Luxemburg',
      2: 'LU',
      3: 'LUX',
      n: 442
    }, {
      name: 'Macau',
      2: 'MO',
      3: 'MAC',
      n: 446
    }, {
      name: 'Madagaskar',
      2: 'MG',
      3: 'MDG',
      n: 450
    }, {
      name: 'Makedonien',
      2: 'MK',
      3: 'MKD',
      n: 807
    }, {
      name: 'Malawi',
      2: 'MW',
      3: 'MWI',
      n: 454
    }, {
      name: 'Malaysia',
      2: 'MY',
      3: 'MYS',
      n: 458
    }, {
      name: 'Maldiverna',
      2: 'MV',
      3: 'MDV',
      n: 462
    }, {
      name: 'Mali',
      2: 'ML',
      3: 'MLI',
      n: 466
    }, {
      name: 'Malta',
      2: 'MT',
      3: 'MLT',
      n: 470
    }, {
      name: 'Marocko',
      2: 'MA',
      3: 'MAR',
      n: 504
    }, {
      name: 'Marshallöarna',
      2: 'MH',
      3: 'MHL',
      n: 584
    }, {
      name: 'Martinique',
      2: 'MQ',
      3: 'MTQ',
      n: 474
    }, {
      name: 'Mauretanien',
      2: 'MR',
      3: 'MRT',
      n: 478
    }, {
      name: 'Mauritius',
      2: 'MU',
      3: 'MUS',
      n: 480
    }, {
      name: 'Mayotte',
      2: 'YT',
      3: 'MYT',
      n: 175
    }, {
      name: 'Mexiko',
      2: 'MX',
      3: 'MEX',
      n: 484
    }, {
      name: 'Mikronesiens federerade stater',
      2: 'FM',
      3: 'FSM',
      n: 583
    }, {
      name: 'Mocambique',
      2: 'MZ',
      3: 'MOZ',
      n: 508
    }, {
      name: 'Moldavien',
      2: 'MD',
      3: 'MDA',
      n: 498
    }, {
      name: 'Monaco',
      2: 'MC',
      3: 'MCO',
      n: 492
    }, {
      name: 'Mongoliet',
      2: 'MN',
      3: 'MNG',
      n: 496
    }, {
      name: 'Montenegro',
      2: 'ME',
      3: 'MNE',
      n: 499
    }, {
      name: 'Montserrat',
      2: 'MS',
      3: 'MSR',
      n: 500
    }, {
      name: 'Namibia',
      2: 'NA',
      3: 'NAM',
      n: 516
    }, {
      name: 'Nauru',
      2: 'NR',
      3: 'NRU',
      n: 520
    }, {
      name: 'Nederländerna',
      2: 'NL',
      3: 'NLD',
      n: 528
    }, {
      name: 'Nederländska Antillerna',
      2: 'AN',
      3: 'ANT',
      n: 530
    }, {
      name: 'Nepal',
      2: 'NP',
      3: 'NPL',
      n: 524
    }, {
      name: 'Nicaragua',
      2: 'NI',
      3: 'NIC',
      n: 558
    }, {
      name: 'Niger',
      2: 'NE',
      3: 'NER',
      n: 562
    }, {
      name: 'Nigeria',
      2: 'NG',
      3: 'NGA',
      n: 566
    }, {
      name: 'Niue',
      2: 'NU',
      3: 'NIU',
      n: 570
    }, {
      name: 'Nordkorea',
      2: 'KP',
      3: 'PRK',
      n: 408
    }, {
      name: 'Nordmarianerna',
      2: 'MP',
      3: 'MNP',
      n: 580
    }, {
      name: 'Norfolkön',
      2: 'NF',
      3: 'NFK',
      n: 574
    }, {
      name: 'Norge',
      2: 'NO',
      3: 'NOR',
      n: 578
    }, {
      name: 'Nya Kaledonien',
      2: 'NC',
      3: 'NCL',
      n: 540
    }, {
      name: 'Nya Zeeland',
      2: 'NZ',
      3: 'NZL',
      n: 554
    }, {
      name: 'Oman',
      2: 'OM',
      3: 'OMN',
      n: 512
    }, {
      name: 'Pakistan',
      2: 'PK',
      3: 'PAK',
      n: 586
    }, {
      name: 'Palau',
      2: 'PW',
      3: 'PLW',
      n: 585
    }, {
      name: 'Panama',
      2: 'PA',
      3: 'PAN',
      n: 591
    }, {
      name: 'Papua Nya Guinea',
      2: 'PG',
      3: 'PNG',
      n: 598
    }, {
      name: 'Paraguay',
      2: 'PY',
      3: 'PRY',
      n: 600
    }, {
      name: 'Peru',
      2: 'PE',
      3: 'PER',
      n: 604
    }, {
      name: 'Pitcairn',
      2: 'PN',
      3: 'PCN',
      n: 612
    }, {
      name: 'Polen',
      2: 'PL',
      3: 'POL',
      n: 616
    }, {
      name: 'Portugal',
      2: 'PT',
      3: 'PRT',
      n: 620
    }, {
      name: 'Puerto Rico',
      2: 'PR',
      3: 'PRI',
      n: 630
    }, {
      name: 'Qatar',
      2: 'QA',
      3: 'QAT',
      n: 634
    }, {
      name: 'Reunion',
      2: 'RE',
      3: 'REU',
      n: 638
    }, {
      name: 'Rumänien',
      2: 'RO',
      3: 'ROM',
      n: 642
    }, {
      name: 'Rwanda',
      2: 'RW',
      3: 'RWA',
      n: 646
    }, {
      name: 'Ryssland',
      2: 'RU',
      3: 'RUS',
      n: 643
    }, {
      name: 'Saint Kitts och Nevis',
      2: 'KN',
      3: 'KNA',
      n: 659
    }, {
      name: 'Saint Lucia',
      2: 'LC',
      3: 'LCA',
      n: 662
    }, {
      name: 'Saint Pierre och Miquelon',
      2: 'PM',
      3: 'SPM',
      n: 666
    }, {
      name: 'Saint Vincent och Grenadinerna',
      2: 'VC',
      3: 'VCT',
      n: 670
    }, {
      name: 'Salomonöarna',
      2: 'SB',
      3: 'SLB',
      n: 90
    }, {
      name: 'Samoa',
      2: 'WS',
      3: 'WSM',
      n: 882
    }, {
      name: 'San Marino',
      2: 'SM',
      3: 'SMR',
      n: 674
    }, {
      name: 'Sankta Helena',
      2: 'SH',
      3: 'SHN',
      n: 654
    }, {
      name: 'Sao Tome och Principe',
      2: 'ST',
      3: 'STP',
      n: 678
    }, {
      name: 'Saudiarabien',
      2: 'SA',
      3: 'SAU',
      n: 682
    }, {
      name: 'Schweiz',
      2: 'CH',
      3: 'CHE',
      n: 756
    }, {
      name: 'Senegal',
      2: 'SN',
      3: 'SEN',
      n: 686
    }, {
      name: 'Serbien',
      2: 'RS',
      3: 'SRB',
      n: 688
    }, {
      name: 'Seychellerna',
      2: 'SC',
      3: 'SYC',
      n: 690
    }, {
      name: 'Sierra Leone',
      2: 'SL',
      3: 'SLE',
      n: 694
    }, {
      name: 'Singapore',
      2: 'SG',
      3: 'SGP',
      n: 702
    }, {
      name: 'Slovakien',
      2: 'SK',
      3: 'SVK',
      n: 703
    }, {
      name: 'Slovenien',
      2: 'SI',
      3: 'SVN',
      n: 705
    }, {
      name: 'Somalia',
      2: 'SO',
      3: 'SOM',
      n: 706
    }, {
      name: 'Spanien',
      2: 'ES',
      3: 'ESP',
      n: 724
    }, {
      name: 'Sri Lanka',
      2: 'LK',
      3: 'LKA',
      n: 144
    }, {
      name: 'Storbritannien',
      2: 'GB',
      3: 'GBR',
      n: 826
    }, {
      name: 'Sudan',
      2: 'SD',
      3: 'SDN',
      n: 736
    }, {
      name: 'Surinam',
      2: 'SR',
      3: 'SUR',
      n: 740
    }, {
      name: 'Svalbard och Jan Mayen',
      2: 'SJ',
      3: 'SJM',
      n: 744
    }, {
      name: 'Sverige',
      2: 'SE',
      3: 'SWE',
      n: 752
    }, {
      name: 'Swaziland',
      2: 'SZ',
      3: 'SWZ',
      n: 748
    }, {
      name: 'Sydafrika',
      2: 'ZA',
      3: 'ZAF',
      n: 710
    }, {
      name: 'Sydkorea',
      2: 'KR',
      3: 'KOR',
      n: 410
    }, {
      name: 'Syrien',
      2: 'SY',
      3: 'SYR',
      n: 760
    }, {
      name: 'Tadzjikistan',
      2: 'TJ',
      3: 'TJK',
      n: 762
    }, {
      name: 'Taiwan',
      2: 'TW',
      3: 'TWN',
      n: 158
    }, {
      name: 'Tanzania',
      2: 'TZ',
      3: 'TZA',
      n: 834
    }, {
      name: 'Tchad',
      2: 'TD',
      3: 'TCD',
      n: 148
    }, {
      name: 'Thailand',
      2: 'TH',
      3: 'THA',
      n: 764
    }, {
      name: 'Tjeckien',
      2: 'CZ',
      3: 'CZE',
      n: 203
    }, {
      name: 'Togo',
      2: 'TG',
      3: 'TGO',
      n: 768
    }, {
      name: 'Tokelau',
      2: 'TK',
      3: 'TKL',
      n: 772
    }, {
      name: 'Tonga',
      2: 'TO',
      3: 'TON',
      n: 776
    }, {
      name: 'Trinidad och Tobago',
      2: 'TT',
      3: 'TTO',
      n: 780
    }, {
      name: 'Tunisien',
      2: 'TN',
      3: 'TUN',
      n: 788
    }, {
      name: 'Turkiet',
      2: 'TR',
      3: 'TUR',
      n: 792
    }, {
      name: 'Turkmenistan',
      2: 'TM',
      3: 'TKM',
      n: 795
    }, {
      name: 'Turks och Caicosöarna',
      2: 'TC',
      3: 'TCA',
      n: 796
    }, {
      name: 'Tuvalu',
      2: 'TV',
      3: 'TUV',
      n: 798
    }, {
      name: 'Tyskland',
      2: 'DE',
      3: 'DEU',
      n: 276
    }, {
      name: 'Uganda',
      2: 'UG',
      3: 'UGA',
      n: 800
    }, {
      name: 'Ukraina',
      2: 'UA',
      3: 'UKR',
      n: 804
    }, {
      name: 'Ungern',
      2: 'HU',
      3: 'HUN',
      n: 348
    }, {
      name: 'Uruguay',
      2: 'UY',
      3: 'URY',
      n: 858
    }, {
      name: 'USA',
      2: 'US',
      3: 'USA',
      n: 840
    }, {
      name: 'Uzbekistan',
      2: 'UZ',
      3: 'UZB',
      n: 860
    }, {
      name: 'Vanuatu',
      2: 'VU',
      3: 'VUT',
      n: 548
    }, {
      name: 'Vatikanstaten',
      2: 'VA',
      3: 'VAT',
      n: 336
    }, {
      name: 'Västsahara',
      2: 'EH',
      3: 'ESH',
      n: 732
    }, {
      name: 'Venezuela',
      2: 'VE',
      3: 'VEN',
      n: 862
    }, {
      name: 'Vietnam',
      2: 'VN',
      3: 'VNM',
      n: 704
    }, {
      name: 'Vitryssland',
      2: 'BY',
      3: 'BLR',
      n: 112
    }, {
      name: 'Wallis- och Futunaöarna',
      2: 'WF',
      3: 'WLF',
      n: 876
    }, {
      name: 'Yemen',
      2: 'YE',
      3: 'YEM',
      n: 887
    }, {
      name: 'Yttre USA öarna',
      2: 'UM',
      3: 'UMI',
      n: 581
    }, {
      name: 'Zaire',
      2: 'ZR',
      3: 'ZAR',
      n: 180
    }, {
      name: 'Zambia',
      2: 'ZM',
      3: 'ZMB',
      n: 894
    }, {
      name: 'Zimbabwe',
      2: 'ZW',
      3: 'ZWE',
      n: 716
    }, {
      name: 'Österrike',
      2: 'AT',
      3: 'AUT',
      n: 40
    }, {
      name: 'Östtimor',
      2: 'TP',
      3: 'TMP',
      n: 626
    }];
  }
}

/*
 * Public API Surface of theme
 */

/**
 * Generated bundle index. Do not edit.
 */

export { CANNOT_BE_ORG_ERROR_MESSAGE, DEFAULT_ERROR_MESSAGE, DUPLICATED_ERROR_MESSAGE, FooterComponent, FormatAccountDirective, FormatAccountPipe, FormatNumberDirective, LogoComponent, PersonnummerValidatorDirective, SPBConstants, SettingsComponent, SpbPaginatorSv, StepperScrollDirective, UNDERAGE_ERROR_MESSAGE, accountNoValidator, formatAccountNumber, pnrValidator, pnrValidatorExtended, validatePersonnummerControl };
