import {HttpEvent, HttpHandlerFn, HttpRequest} from '@angular/common/http'
import {inject} from '@angular/core'
import {first, Observable, switchMap} from 'rxjs'
import {ConfigService} from '../services/config.service'

/**
 * Intercept all requests and add auth token. We should however
 * do this only on white listed requests so that we do not
 * send our tokens when not needed. In its simplest form this
 * assumes that all outgoing requests after the token is set
 * should have it.
 */
export const authInterceptor = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  const configService = inject(ConfigService)
  return configService.accessToken$.pipe(
    first(),
    switchMap(token => {
      // Proceed to modify only whitelisted requests
      if (req.url?.includes('service') || req.url?.includes('admin') || req.url?.includes('user')) {
        const authReq = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${token}`)
        })
        return next(authReq) // Pass modified request
      }

      // For any request not included in the whitelist, pass it unmodified
      return next(req)
    })
  )
}