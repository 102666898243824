import {HttpClient} from '@angular/common/http'
import {inject, Injectable} from '@angular/core'
import {SparbankenUser} from '@sparbanken-syd/sparbanken-syd-bankid'
import {map, Observable} from 'rxjs'
import {environment} from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private http = inject(HttpClient)

  public getUsers(): Observable<SparbankenUser[]> {
    const url = `${environment.authServiceUrl}/users`
    return this.http.get<SparbankenUser[]>(url)
  }

  /**
   * Retrieves all offices of the Sparbanken.
   */
  public getOffices(): Observable<string[]> {
    const url = `${environment.authServiceUrl}/data/offices`
    return this.http.get<string[]>(url)
      .pipe(
        map(offices => offices.sort())
      )
  }

}
