import {Component, OnInit} from '@angular/core'
import {Router} from '@angular/router'
import {BankIdComponent} from '@sparbanken-syd/sparbanken-syd-bankid'
import {first} from 'rxjs'
import {filter} from 'rxjs/operators'
import {environment} from '../../environments/environment'
import {ADMIN_ROUTE_PATH} from '../application/app'
import {ConfigService, ILogInState} from '../services/config.service'

@Component({
  selector: 'spb-login',
  templateUrl: './login.component.html',
  imports: [
    BankIdComponent
  ],
  styleUrls: ['./login.component.scss']

})
export class LoginComponent implements OnInit {
  public domain = environment.domain
  /**
   * The URL BankID shall use.
   */
  public bankIdUrl = environment.authServiceUrl


  /**
   * Constructor
   *
   * @param configService
   * @param router - To be able to get out of here.
   */
  constructor(
    public configService: ConfigService,
    private router: Router
  ) {

  }

  public ngOnInit(): void {
    this.configService.logInState$.pipe(
      filter((config: ILogInState | null) => config!.ready),
      first()
    ).subscribe(
      {
        /**
         * Which route to navigate after logged in
         */
        next: () => this.router.navigate([ADMIN_ROUTE_PATH])
      })
  }
}
