import {Routes} from '@angular/router'
import {LOGIN_ROUTE_PATH} from './application/app'
import {authGuard} from './application/auth.guard'

import {IdListComponent} from './id-list/id-list.component'
import {LoginComponent} from './login/login.component'

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: LOGIN_ROUTE_PATH
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'admin',
    component: IdListComponent,
    canActivate: [authGuard]
  },
  {
    path: '**',
    redirectTo: LOGIN_ROUTE_PATH
  }
]