<div class="list-holder">
  <div class="top-holder">
    <mat-form-field [style.margin-right.px]="15">
      <mat-label>Sök</mat-label>
      <input [formControl]="filterControl" matInput name="filterTrans">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Välj kontor</mat-label>
      <mat-select (selectionChange)="this.dataSource.filter = $event.value">
        <mat-option value="">Samtliga kontor</mat-option>
        @for (office of offices; track $index) {
          <mat-option [value]="office">{{ office }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <div class="pointer">
      <button
        (click)="updateList()"
        color="primary"
        mat-mini-fab
      >
        <mat-icon>cached</mat-icon>
      </button>
    </div>
  </div>
  @if (!showTable) {
    <div style="display: flex; justify-content: center">
      <mat-progress-spinner
        mode="indeterminate">
      </mat-progress-spinner>
    </div>
  } @else {
    <div>
      <div class="mat-elevation-z8">
        <table [dataSource]="dataSource" mat-table matSort
               matSortActive="timeStamp" matSortDirection="desc"
        >
          <caption>Lista över legitimeringar</caption>

          <ng-container matColumnDef="timeStamp">
            <th *matHeaderCellDef id="timeStamp" mat-header-cell
                mat-sort-header> Tidpunkt
            </th>
            <td
              *matCellDef="let element"
              [matTooltip]="convertDate(element.gdprRemove)"
              mat-cell matTooltipPosition="before">
              {{ element.timeStamp }}
            </td>
          </ng-container>

          <ng-container matColumnDef="personalNumber">
            <th *matHeaderCellDef id="personalNumber" mat-header-cell
                mat-sort-header> Personnummer
            </th>
            <td *matCellDef="let element" mat-cell>
              {{ element.personalNumber }}

              @if (!element.personalNumber) {
                <ng-container>
                  Misslyckad
                </ng-container>
              }
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th *matHeaderCellDef id="name" mat-header-cell
                mat-sort-header> Namn
            </th>
            <td *matCellDef="let element" mat-cell>
              {{ element.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="age">
            <th *matHeaderCellDef id="age" mat-header-cell
                mat-sort-header> Ålder
            </th>
            <td *matCellDef="let element" mat-cell>
              {{ element.age }}
            </td>
          </ng-container>

          <ng-container matColumnDef="coworkerName">
            <th *matHeaderCellDef id="coworkerName" mat-header-cell
                mat-sort-header> Personal
            </th>
            <td *matCellDef="let element" mat-cell>
              {{ element.coworkerName }}
            </td>
          </ng-container>

          <ng-container matColumnDef="office">
            <th *matHeaderCellDef id="office" mat-header-cell
                mat-sort-header> Kontor
            </th>
            <td *matCellDef="let element" mat-cell>
              {{ element.office }}
            </td>
          </ng-container>

          <ng-container matColumnDef="copy">
            <th *matHeaderCellDef id="copy" mat-header-cell>
            </th>
            <td (click)="copyInfo(element)" *matCellDef="let element"
                class="pointer" mat-cell>
          <span class="material-symbols-outlined">
            content_copy</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="save">
            <th *matHeaderCellDef id="save" mat-header-cell>
            </th>
            <td (click)="saveInfo(element)" *matCellDef="let element"
                class="pointer" mat-cell>
              <span class="material-symbols-outlined">save</span>
            </td>
          </ng-container>

          <tr *matHeaderRowDef="columnsToDisplay" mat-header-row></tr>
          <tr *matRowDef="let element; columns: columnsToDisplay" mat-row></tr>

          <tr *matNoDataRow class="no-data">
            <td [attr.colspan]="columnsToDisplay.length" class="no-data">
              Inga legitimeringar hittades
            </td>
          </tr>
        </table>
        <mat-paginator [length]="100"
                       [pageSizeOptions]="[5, 10, 25, 100, 500, 1000]"
                       [pageSize]="10"
                       aria-label="Select page">
        </mat-paginator>
      </div>
    </div>
  }
  <div [class.visible]="copyText" class="popup-text">{{ copyText }}</div>
</div>
